import React from "react";
import "./OurPartners.css";
import { Container, Col, Row } from "react-bootstrap";
import ourPartnersImg from "../../Assets/ourPartners/partners.png";
import ourPartnersImg1 from "../../Assets/ourPartners/11.png";

function OurPartners() {
  return (
    <div style={{ backgroundColor: "black" }}>
      <Container>
        <div
          style={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingTop: "200px",
          }}
        >
          {/* <Row>
            <div className="p-box">
              <img src={ourPartnersImg1} alt="" />
            </div>
          </Row>
          <Row>
            <Col></Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col></Col>
          </Row> */}
          <div>
            <p className="p-heading">OurPartners</p>
            <div className="underLine"></div>
          </div>

          <div>
            <img style={{ maxWidth: "100%" }} src={ourPartnersImg} alt="" />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default OurPartners;
