import React from "react";
import "./Services.css";
import { Modal, Button } from "react-bootstrap";
import { Fade } from "react-reveal";
import { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import dmimg from "../../Assets/imgservice/3545757.jpg";
import seoimg from "../../Assets/imgservice/61513.jpg";
import wcbimg from "../../Assets/imgservice/8595513.jpg";
import demo from "../../Assets/Images/123.jpg";
import demo1 from "../../Assets/Images/345.jpg";
import { BsDot } from "react-icons/bs";
import menu from "../../Assets/Images/menu icons.svg";
import Gradient1 from "../../Assets/Images/Gradient red.svg";
import logo1 from "../../Assets/Images/Nlogo_White_footage.svg";
import line from "../../Assets/Images/Line Design.svg";
import ellipse from "../../Assets/Images/Ellipse1.svg";
import lineor from "../../Assets/Images/lineor.svg";
import cra from "../../Assets/Images/Client_Right arrow.svg";
import img1 from "../../Assets/Images/Image1m.svg";
import img1sh from "../../Assets/Images/Image1msh.svg";
import img2 from "../../Assets/Images/Image2.svg";
import img2sh from "../../Assets/Images/Image2sh.svg";
import recg from "../../Assets/Images/recgreen.svg";
import devimg from "../../Assets/Images/App development image.svg";
import cliri from "../../Assets/Images/Client_Right arrow.svg";
import { useTranslation } from "react-i18next";

function Services() {
  const { t } = useTranslation();
  const [fadeProp, setFadeProp] = useState({
    fade: "fade-out",
  });
  useEffect(() => {
    const timeout = setInterval(() => {
      if (fadeProp.fade === "fade-out") {
        setFadeProp({
          fade: "fade-in-right",
        });
      } else {
        setFadeProp({
          fade: "fade-in-right",
        });
      }
    }, 4000);
    return () => clearInterval(timeout);
  }, [fadeProp]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showwd, setShowModal3] = useState(false);
  const handleClosewd = () => setShowModal3(false);
  const handleShowwd = () => setShowModal3(true);

  const [showdm, setShowModal1] = useState(false);
  const handleClosedm = () => setShowModal1(false);
  const handleShowdm = () => setShowModal1(true);

  const [showwcb, setShowModal2] = useState(false);
  const handleClosewcb = () => setShowModal2(false);
  const handleShowwcb = () => setShowModal2(true);
  return (
    <div>
      <div className="serv">
        <Container fluid>
          <Row>
            <Col xs={12} md={6}>
              <img className="grad2" src={ellipse} style={{ width: 450 }} />
            </Col>
          </Row>
          <Row style={{ position: "relative" }}>
            <Col xs={12} md={6}>
              <div
                className="css-typing3 wesl"
                style={{ fontSize: 49, fontWeight: "medium", color: "white" }}
              >
                <div>{t("services.we_provide")}</div>
                <div className="wor">
                  {t("services.solutions_for_your_business")}
                  {/* solutions for Your Business */}
                  <span
                    style={{
                      color: "#cc6719",
                      fontSize: "50px",
                      fontWeight: 800,
                    }}
                  >
                    .
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="about3">
        <Row style={{ position: "relative" }}>
          <Col xs={12} md={7}>
            <div>
              <img src={lineor} className="lineo" style={{ width: 650 }} />
            </div>
            {/* <div style={{ position: "relative" }}>
              <img src={cra} className="crad" />
            </div> */}
            <div
              className="webu"
              style={{ fontSize: 42, fontWeight: "550", marginTop: 225 }}
            >
              {t("services.high_performing_applications")}
              {/* We Build high perfoming application */}
            </div>
            <p
              className="paraf"
              style={{
                fontSize: 18,
                fontWeight: 300,
                marginTop: 20,
                marginBottom: 50,
              }}
            >
              {t("services.an_application_paragarph")}
              {/* An application that allows users to connect all their social
              profiles, import their data and process it to show some
              reports.Building on application which performs well at any scale
              is becoming a more common expectation of business owners around
              the world. */}
            </p>
            <div className="smdiv" style={{ display: "flex" }}>
              <BsDot size={30} style={{ color: "#ff7a00", marginBottom: 3 }} />
              <div style={{ marginTop: 2 }}>
                {t("services.digital_marketing_solutions_for_tomarrow")}
                {/* Digital Marketing Solutions for Tomarrow */}
              </div>
            </div>
            <div className="smdiv" style={{ display: "flex" }}>
              <BsDot size={30} style={{ color: "#ff7a00" }} />
              <div style={{ marginTop: 2 }}>
                {t("services.our_talented_experienced_marketing_agency")}
                {/* Our Talented & Experienced Marketing Agency */}
              </div>
            </div>
            <div className="smdiv" style={{ display: "flex" }}>
              <BsDot size={30} style={{ color: "#ff7a00" }} />
              <div style={{ marginTop: 2 }}>
                {t("services.create_your_own_skin_to_match_your_brand")}
                {/* Create Your Own Skin to match Your brand */}
              </div>
            </div>
          </Col>
          <Col xs={12} md={5}>
            <img src={img1} className="img1" style={{ width: "40%" }} />
            <img src={img1sh} className="img1sh" style={{ width: 550 }} />
          </Col>
        </Row>
      </div>

      <div className="about4">
        <Row>
          <Col className="col" style={{ position: "relative" }} xs={12} md={5}>
            <img src={img2} className="img2" style={{ width: "110%" }} />
            <img src={img2sh} className="img2sh" />
          </Col>
          <Col xs={12} md={7}>
            <div
              className="webi"
              style={{
                fontSize: 42,
                fontWeight: "550",
                marginTop: 260,
                color: "white",
              }}
            >
              {t("services.custom_front_end_and_back_end_development_services")}
              {/* Custom Front-End and back-End Development services */}
            </div>
            <p
              className="parag"
              style={{
                fontSize: 18,
                fontWeight: 300,
                marginTop: 20,
                color: "white",
              }}
            >
              {t("services.custom_paragraph")}
              {/* An application that allows users to connect all their social
              profiles, import their data and process it to show some
              reports.Building an application which performs well at any scale
              is becoming a more common expecttion of business owners around the
              world. */}
            </p>
            <div className="smdiv" style={{ display: "flex" }}>
              <BsDot size={30} style={{ color: "#ff7a00", marginBottom: 3 }} />
              <div style={{ marginTop: 2, color: "white" }}> {t('services.agile_approach')}</div>
            </div>
            <div className="smdiv" style={{ display: "flex" }}>
              <BsDot size={30} style={{ color: "#ff7a00" }} />
              <div style={{ marginTop: 2, color: "white" }}>
                {t('services.continous_delivery')}
                {/* Continous delivery */}
              </div>
            </div>
            <div className="smdiv" style={{ display: "flex" }}>
              <BsDot size={30} style={{ color: "#ff7a00" }} />
              <div style={{ marginTop: 2, color: "white" }}>{t('services.test_driven')}</div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="picdiv">
        <Row>
          <Col
            className="colcol"
            style={{ position: "relative" }}
            xs={12}
            md={1}
            lg={1}
          >
            <img className="recg" src={recg} style={{ height: "60%" }} />
          </Col>
          <Col style={{ position: "relative" }} xs={12} md={6} lg={4}>
            <div className="num">01</div>
            <div className="appnm">{t('services.application')}</div>
            <div className="depnm">{t('services.development')}</div>
            <div className="praf" style={{ fontSize: 18, fontWeight: 300 }}>
              {t('services.our_expert_paragraph')}
            </div>
            <div className="wwdar">
              {/* <div className="wtwdo" style={{ fontSize: 20 }}>
                What we do?
              </div> */}
            </div>
          </Col>
          <Col xs={12} md={5} lg={7}>
            <img className="devimg" src={devimg} style={{ width: "110%" }} />
          </Col>
        </Row>
      </div>

      {/* here to there */}

      <section className="section-s3">
        <Row>
          <Col sm={6}>
            {" "}
            <img className="dmimg" src={dmimg} style={{ width: 350 }} />
          </Col>

          <Col sm={6}>
            <Fade right>
              <div style={{ fontSize: 42, fontWeight: "550", marginTop: 60 }}>
                 {t('services.digital_marketing')}
                <p style={{ fontSize: 18, fontWeight: 300 }}>
                 {t('services.digital_marketing_paragraph')}
                  <a onClick={handleShowdm} style={{ color: "blue" }}>
                    {" "}
                    &nbsp;{t('services.read_more')}
                  </a>
                </p>
              </div>
            </Fade>
          </Col>
        </Row>

        <Modal show={showdm} className="modal" onHide={handleClosedm}>
          <Modal.Header>
            <Modal.Title> {t("services.digital_marketing_modal")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modalbody" style={{ fontSize: 18 }}>  {t('services.digital_marketing_modal_paragraph')}
            <div className="modaldot">
              <Row>
                <Col xs={12} md={6}>
                  <ul>
                    {" "}
                    <br></br>
                    <li>{t('services.search_engine_optimization')}</li>
                    <li>{t("services.pay_pre_click")}</li>
                    <li>{t("services.video_branding")}</li>
                    <li>{t("services.business_listing_marketing")} </li>
                    <li>{t("services.paper_click")}</li>
                    <li>{t("services.conversion_rate_optimization")}</li>
                  </ul>
                </Col>
                <Col xs={12} md={6}>
                  <ul>
                    {" "}
                    <br></br>
                    <li>{t("services.google_ads")}</li>
                    <li>{t("services.social_media_marketing")}</li>
                    <li>{t("services.content_digitisation")}</li>
                    <li>{t("services.creative_ad_campaings")}</li>
                    <li>{t("services.email_marketing")}</li>
                    <li>{t("services.reels_and_podcasts_etc")}</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <div className="close">
            <a onClick={handleClosedm} style={{ fontSize: 22, color: "blue" }}>
              {t('services.close')}
            </a>
          </div>
        </Modal>
      </section>

      <section className="section-s4">
        <Row>
          <Col sm={6}>
            <Fade left>
              <div style={{ fontSize: 42, fontWeight: "550", marginTop: 150 }}>
                {t('services.wholistic_company_branding')}
                
              </div>

              <p style={{ fontSize: 18, fontWeight: 300 }}>
                {t('services.digital_marketing_umprella')}
                <a onClick={handleShowwcb} style={{ color: "blue" }}>
                  {" "}
                  &nbsp; 
                  {t('services.read_more')}
                </a>
              </p>
            </Fade>
          </Col>
          <Col sm={6}>
            <img className="wcbimg" src={wcbimg} />
          </Col>
        </Row>

        <Modal show={showwcb} className="modal" onHide={handleClosewcb}>
          <Modal.Header>
            <Modal.Title>
              {t('services.wholistic_company_branding')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modalbody" style={{ fontSize: 18 }}>
            {t('services.wholistic_company_branding_paragraph')}
            
            <div className="modaldot">
              <Row>
                <Col xs={12} md={6}>
                  <ul>
                    {" "}
                    <br></br>
                    <li>{t('services.logos')}</li>
                    <li>{t("services.cateloge_flyres")}</li>
                  </ul>
                </Col>
                <Col xs={12} md={6}>
                  <ul>
                    {" "}
                    <br></br>
                    <li>
                      {t('services.brochures_profile')}</li>
                    <li>{t('services.corperate_identity_design')} </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <div className="close">
            <a onClick={handleClosewcb} style={{ fontSize: 22, color: "blue" }}>
              {t('services.close')}
            </a>
          </div>
        </Modal>
      </section>
      <section className="section-s5">
        <Row>
          <Col sm={6}>
            {" "}
            <img className="seoimg" src={seoimg} style={{ width: 350 }} />
          </Col>
          <Col sm={6}>
            <Fade right>
              <div
                className="webu"
                style={{ fontSize: 42, fontWeight: "550", marginTop: 20 }}
              >
                {t('services.seo_consulting_services')}
                
              </div>
              <div className="seoservice">
                <Row>
                  <Col sm={6}>
                    <ul>
                      {" "}
                      <br></br>
                      <li>{t('services.e-commerce_seo')} </li>
                      <li>{t('services.local_and_multi-regional_seo')} </li>
                      <li>{t('services.industries_specific_seo')}</li>
                      <li>{t('services.international_seo')}</li>
                    </ul>
                  </Col>
                  <Col sm={6}>
                    <ul>
                      {" "}
                      <br></br>
                      <li>{t('services.mobile_and_video_seo')}</li>
                      <li>{t('services.link_auditing_penalties_removal_seo')}</li>
                      <li>{t('services.compatative_analysis_technical_seo')}</li>
                      <li>{t('services.onepage_seo')}</li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Fade>
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default Services;



// eslint-disable-next-line no-lone-blocks
{
  /* <Container>
             <div style={{padding:30}}>
               <Row>
               <Col xs={12} md={8} lg={8}>
                    <p style={{fontSize:50, fontWeight:'650', color:'black'}}>We help companies<br />
                   transform into<br />
                  progressive growth for<br /> their future</p>
                
                </Col>
               </Row> 
              </div>   
           </Container>
   
           <hr></hr>
            <div>
              <Container>               
                  <div style={{marginTop:20, padding:20}}>
                        <Row>
                         <Col xs={12} md={6} lg={6}>
                           <div style={{padding:10}}>
                           <img style={{width:'100%', borderRadius:5}} src={demo} />
                           </div>
                          </Col>

                          <Col xs={12} md={6} lg={6}>
                          <div style={{marginLeft:40}}>
                          <p style={{fontSize:25, fontWeight:'bold'}}>We Build High Performing<br/>Application</p>
                          <div className='divv' style={{fontSize:16 }}>An application that allows users to connect all their<br/> 
                          social profiles, import their data and process it to show<br/>
                          some reports.Building an application which performs<br/>
                          well at any scale is bocoming a more common<br/>
                          expectation of business owners around the world
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Digital Markrting Solutions for Tomarrow</div>
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Our Talent & Experienced Marketing Agency</div>
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Create Your Own Skin to Match your Brand</div>
                          </div>
                          </div>
                          </Col>
                        </Row>
                  </div>

                  <div style={{marginTop:20, padding:20}}>
                        <Row>
                          <Col xs={12} md={6} lg={6}>
                          <div>
                          <p style={{fontSize:25, fontWeight:'bold'}}>Enterprise Software</p>
                          <div className='divv' style={{fontSize:16 }}>With deep expertise in full-cyle corporate software<br/>
                          development, NASScript improves abd auotomates<br/>
                          business with unmatched enterprise applications.
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Enterprise Resource planning[ERP]</div>
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Customer Relationship Management[CRM]</div>
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Human Resource Management[HRM]</div>
                          </div>
                          </div>
                          </Col>

                          <Col xs={12} md={6} lg={6}>
                           <div style={{padding:10, marginLeft:40}}>
                           <img style={{width:'100%', borderRadius:5}} src={demo} />
                           </div>
                          </Col>
                        </Row>
                  </div>

                  <div style={{marginTop:20, padding:20}}>
                        <Row>
                         <Col xs={12} md={6} lg={6}>
                           <div style={{padding:10}}>
                           <img style={{width:'100%', borderRadius:5}} src={demo} />
                           </div>
                          </Col>

                          <Col xs={12} md={6} lg={6}>
                          <div style={{marginLeft:40}}>
                          <p style={{fontSize:25, fontWeight:'bold'}}>Custom Front-End and<br/>Back-End Development<br/>Services</p>
                          <div className='divv' style={{fontSize:16 }}>Our front-end and back-end development teams work<br/> 
                          on solutions of defferent complexity and deliver full<br/>
                          cycle development services to make our clients' plans<br/>
                         come true.We build reliable and scalable software.
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Agile Approach</div>
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Continous Delivery</div>
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Test Driven</div>
                          </div>
                          </div>
                          </Col>
                        </Row>
                  </div>
            
              </Container>                                                    
            </div> */
}
