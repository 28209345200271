import "./team.css";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import Member2 from "../../Assets/img/Member2.jpg";
import { Card, CardGroup } from "react-bootstrap";
// import member3 from '../../Assets/teammembers/rafi.png'
import { Button } from "react-bootstrap";
// import Carousel from 'react-bootstrap/Carousel';
// import Carousel from 'react-multi-carousel';
import Carousel from "react-grid-carousel";
import anas2 from "../../Assets/Team/anas2.png";
import chairman from "../../Assets/Team/chairman.png";
import { useTranslation } from 'react-i18next';


export default function Model3() {
  const { t } = useTranslation();

  return (
    <div>
      <section id="team" className="team section-bg">
        <div className="container" data-aos="fade-up">
          <div
            className="section-title"
            style={{ margin: 50, textAlign: "center" }}
          >
            <h2 style={{ color: "white" }}>{t('ourTeam.our_team')}</h2>
            <hr style={{ color: "white" }}></hr>
          </div>
          <div className="row">
            <div>
              <div className="member d-flex align-items-start">
                <div className="pic">
                  <img src={anas2} className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>{t('ourTeam.ceo_name')}</h4>
                  <span> {t('ourTeam.ceo')}</span>
                  <p>
                   {t('ourTeam.about_ceo')}
                  </p>
                </div> 
              </div>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div>
              <div className="member d-flex align-items-start">
                <div className="member-info">
                  <h4> {t('ourTeam.chairman_name')}</h4>
                  <span>{t('ourTeam.chairman')}</span>
                  <p>
      {t('ourTeam.about_cheirman')}
                  </p>
                </div>
                <div className="pic" style={{ width: 385 }}>
                  <img src={chairman} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="row">

         
            {/* 
<div >
  <br></br>
  <div className="member d-flex align-items-start">
    <div className="pic" style={{width:385}}><img style={{width:600}} src={fazal} className="img-fluid" /></div>
    <div className="member-info">
      <h4>FASALURAHMAN</h4>
      <span>Managing Director</span>
      <p>It is with immense pride and gratitude that I address this exceptional group. Our journey has been one of perseverance, innovation, and collaboration. As we continue to evolve, let us stay true to our core values, exceed customer expectations, and nurture a culture of excellence.

With your dedication, we will continue to thrive and make a lasting impact on our industry.</p>
     
    </div>
  </div>
</div> */}
          </div>
          <br></br>
        </div>
        <br></br>
      </section>

      {/* <Carousel loop>
        <Carousel.Item>
          <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">
              <div
                className="section-title"
                style={{ margin: 50, textAlign: "center" }}
              >
                <h2 style={{ color: "white", backgroundColor: "#33cc33" }}>
                  {" "}
                  PHP DEVELOPERS
                </h2>
                <hr style={{ color: "black" }}></hr>
              </div>

              <div className="row">
                <div className="col-lg-3">
                  <div className="member">
                    <div className="pic" style={{ width: 150, marginLeft: 35 }}>
                      <img src="{rafi}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      <h4>RAFID K A </h4>
                      <span>PHP Developer </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: -8,
                      }}
                    >
                      <img src="" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      <h4>SHAMEER </h4>
                      <span>PHP Developer </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: -8,
                      }}
                    >
                      <img src="" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      <h4>SHIBIL K </h4>
                      <span>PHP Developer </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Carousel.Item>

        <Carousel.Item>
          <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">
              <div
                className="section-title"
                style={{ margin: 50, textAlign: "center" }}
              >
                <h2 style={{ color: "black" }}>SOFTWARE DEVELOPERS</h2>
                <hr style={{ color: "black" }}></hr>
              </div>

              <div className="row">
                <div className="col-lg-3">
                  <div className="member">
                    <div className="pic" style={{ width: 150, marginLeft: 35 }}>
                      <img src="{shafi}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      <h4>SHAFI P P</h4>
                      <span>.NET Developer </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: -8,
                      }}
                    >
                      <img src="{ayisha}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      <h4>AYISHA</h4>
                      <span>.NET Developer </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: -8,
                      }}
                    >
                      <img src="{shahala}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      <h4>SHAHALA </h4>
                      <span>.NET Developer </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: -8,
                      }}
                    >
                      <img src="{shahid}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      <h4>SHAHID M</h4>
                      <span>Software Developer </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Carousel.Item>
        <Carousel.Item>
          <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">
              <div
                className="section-title"
                style={{ margin: 50, textAlign: "center" }}
              >
                <h2 style={{ color: "black" }}> ANGULAR DEVELOPERS</h2>
                <hr style={{ color: "black" }}></hr>
              </div>

              <div className="row">
                <div className="col-lg-3">
                  <div className="member">
                    <div className="pic" style={{ width: 150, marginLeft: 35 }}>
                      <img src="{manu}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      <h4>MANU PRATHAP</h4>
                      <span>Angular Developer </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: 25,
                      }}
                    >
                      <img src="{haritha}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      <h4>HARITHA P</h4>
                      <span>Angular Developer </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Carousel.Item>
        <Carousel.Item>
          <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">
              <div
                className="section-title"
                style={{ margin: 50, textAlign: "center" }}
              >
                <h2 style={{ color: "black" }}>DIGITAL MARKETING</h2>
                <hr style={{ color: "black" }}></hr>
              </div>

              <div className="row">
                <div className="col-lg-3">
                  <div className="member">
                    <div className="pic" style={{ width: 150, marginLeft: 35 }}>
                      <img src="{jawad}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      <h4>JAWAD K</h4>
                      <span>Creative Designer </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: -8,
                      }}
                    >
                      <img src="{sadikh}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50, marginTop: 35 }}>
                      <h4>SADIKH</h4>
                      <span>Creative Designer </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: -8,
                      }}
                    >
                      <img src="{shameem}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50, marginTop: 35 }}>
                      <h4>SHAMEEM</h4>
                      <span>Digital Marketing Analyst </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: -8,
                      }}
                    >
                      <img src="{ajay}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50, marginTop: 35 }}>
                      <h4>AJAY</h4>
                      <span>Digital Marketing Analyst </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Carousel.Item>
        <Carousel.Item>
          <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">
              <div
                className="section-title"
                style={{ margin: 50, textAlign: "center" }}
              >
                <h2 style={{ color: "black" }}> DIGITAL MARKETING</h2>
                <hr style={{ color: "black" }}></hr>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <div className="member">
                    <div className="pic" style={{ width: 150, marginLeft: 35 }}>
                      <img src="{adullah}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      <h4>ABDULLAH</h4>
                      <span>Content Creator </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: -8,
                      }}
                    >
                      <img src="{ayoob}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50, marginTop: 35 }}>
                      <h4>AYOOB</h4>
                      <span>Content Creator </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: -8,
                      }}
                    >
                      <img src="{afsal}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50, marginTop: 35 }}>
                      <h4>AFSAL</h4>
                      <span>Content Creator </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: -8,
                      }}
                    >
                      <img src="{abid}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50, marginTop: 35 }}>
                      <h4>ABID</h4>
                      <span>Graphic Designer</span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Carousel.Item>
        <Carousel.Item>
          <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">
              <div
                className="section-title"
                style={{ margin: 50, textAlign: "center" }}
              >
                <h2 style={{ color: "black" }}> SALES AND MARKETING</h2>
                <hr style={{ color: "black" }}></hr>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <div className="member">
                    <div className="pic" style={{ width: 150, marginLeft: 35 }}>
                      <img src="{ashraf}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      <h4>ASHRAF</h4>
                      <span>Administrator</span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: -8,
                      }}
                    >
                      <img src="{nizar}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50, marginTop: 35 }}>
                      <h4>NIZAR</h4>
                      <span>Marketing Executive</span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="member">
                    <div
                      className="pic"
                      style={{
                        width: 150,
                        marginLeft: 35,
                        height: 150,
                        marginTop: -8,
                      }}
                    >
                      <img src="{anwar}" className="img-fluid" />
                    </div>
                    <div style={{ marginLeft: 50, marginTop: 35 }}>
                      <h4>ANWAR</h4>
                      <span>Marketing Executive </span>

                      <div className="social">
                        <a href="">
                          <i className="ri-twitter-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-facebook-fill"></i>
                        </a>
                        <a href="">
                          <i className="ri-instagram-fill"></i>
                        </a>
                        <a href="">
                          {" "}
                          <i className="ri-linkedin-box-fill"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Carousel.Item>
      </Carousel> */}
    </div>
  );
}
