import React from "react";
import "./About.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import menu from "../../Assets/Images/menu icons.svg";
import Gradient1 from "../../Assets/Images/Gradient red.svg";
import logo1 from "../../Assets/Images/Nlogo_White_footage.svg";
import line from "../../Assets/Images/Line Design.svg";
import Model3 from "./team";
import { useTranslation } from 'react-i18next';


function About() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="about">
        <Container fluid>
          {/* <Row>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <img src={logo1} className="log" style={{ width: 40 }} />
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ color: "white", marginTop: 8, marginRight: 15 }}>
                  About
                </div>
                <img className="menucls1" src={menu} style={{ width: 35 }} />
              </div>
            </div>
          </Row> */}
          <Row>
            <Col xs={12} md={6}>
              <div
                className="css-typing2"
                style={{
                  marginTop: 170,
                  fontSize: 49,
                  fontWeight: "medium",
                  color: "white",
                }}
              >
                <div>
                  {t('about.delivering_digital')}
                  
                  <br />
                </div>
                <div className="work">
                  {t('about.workplace_solutions')}
                  {/* workplace solutions */}
                  <span
                    style={{ color: "red", fontSize: "50px", fontWeight: 800 }}
                  >
                    .
                  </span>
                </div>
              </div>
            </Col>
           
            <Col xs={12} md={6}>
              <img
              alt=""
                className="grad1"
                src={Gradient1}
                style={{ width: 450, marginTop: 70 }}
              />
            </Col>
          </Row>
        </Container>
      <div className="team">
              <Model3 />
            </div>
      </div>
      <div className="about1">
        <Row>
          <Col xs={12} lg={10}>
            <p
              className="embu"
              style={{ fontSize: 48, fontWeight: "550", marginTop: 80 }}
            >
              <span style={{ color: "red" }}>"</span>
              {t('about.empowering_organizations')}
              {/* Empowering organizations to digitize their business and create a global impact". */}
            </p>

            <p
              className="parag"
              style={{
                fontSize: 20,
                fontWeight: 300,
                marginTop: 75,
                marginBottom: 75,
              }}
            >
              {t('about.software_development_company')}
              {/* NASSCRIPT is a software development company offering a wide range  of services worldwide. Our main objective is to furnish high-quality fast Services at a low cost. We planned to start this    company during the year 2015 by collecting details and funds to    make this a new venture. After all, it was launched in 2020 under   the name 'NAS International Group of Company'. The company officially registered its trademark as 'NASSCRIPT' and starts  working. */}
            </p>
          </Col>
          <Col style={{ position: "relative" }} xs={12} md={2}>
            <div className="lineimg">
              <img className="lineimage" src={line} alt="" style={{ width: 775 }} />
            </div>
          </Col>
        </Row>
      </div>
      
      <div className="hrdiv">
        <div className="about2">
          <Row>
            <Col xs={12} md={5}>
              <div className="a" style={{ color: "red", fontSize: 25 }}>
                {/* What we do ? */}
                {t('about.what_we_do')}
              </div>
              <div
                className="abt"
                style={{ color: "white", fontSize: 48, fontWeight: "medium" }}
              >
                {t('about.design_branding')}
                {/* Design & Branding */}
              </div>
              <div
                className="prag"
                style={{ color: "white", fontSize: 18, marginTop: 20 }}
              >
                {t('about.design_branding_description')}
                {/* It's to fabricate a distinctive identity in comparison to the other brands in the market and also providing impetus to the product defferentiation. */}
              </div>
            </Col>
          </Row>
        </div>
        <hr style={{ height: 2, color: "white" }} />
        <div className="about2">
          <Row>
            <Col xs={12} md={5}>
              <div
                className="abt"
                style={{ color: "white", fontSize: 48, fontWeight: "medium" }}
              >
                {t('about.fully_secured')}
              </div>
              <div
                className="prag"
                style={{ color: "white", fontSize: 18, marginTop: 20 }}
              >
                {t('about.fully_secured_description')}
                {/* Effective IT security for preventing and protecting your
                applications and network. */}
              </div>
            </Col>
          </Row>
        </div>
        <hr style={{ height: 2, color: "white" }} />
        <div className="about2">
          <Row>
            <Col xs={12} md={5}>
              <div
                className="abt"
                style={{ color: "white", fontSize: 48, fontWeight: "medium" }}
              >
                {t('about.high_performance')}
                {/* High Perfomance */}
              </div>
              <div
                className="prag"
                style={{ color: "white", fontSize: 18, marginTop: 20 }}
              >
                {t('about.high_performance_description')}
                {/* We help build mobile and web products for both buisiness and  social purposes in high perfomance.Delivering high perfomanceand  scalable enterprise mobile apps in Android and iOS. */}
              </div>
            </Col>
          </Row>
        </div>
        <hr style={{ height: 2, color: "white" }} />
        <div className="about2">
          <Row>
            <Col xs={12} md={5}>
              <div
                className="abt"
                style={{ color: "white", fontSize: 48, fontWeight: "medium" }}
              >{t('about.best_securities')}
                {/* Best Securities */}
              </div>
              <div
                className="prag"
                style={{ color: "white", fontSize: 18, marginTop: 20 }}
              >
                {t('about.best_securities_description')}
                {/* manage and ensure the overall security of your application and network. */}
              </div>
            </Col>
          </Row>
        </div>
        <hr style={{ height: 2, color: "white" }} />
        <div className="about2">
          <Row>
            <Col xs={12} md={5}>
              <div
                className="abt"
                style={{ color: "white", fontSize: 48, fontWeight: "medium" }}
              >
                {t('about.trusted_service')}
                {/* Trusted Service */}
              </div>
              <div
                className="prag"
                style={{ color: "white", fontSize: 18, marginTop: 20 }}
              >
                 {t('about.trusted_service_description')}
                {/* Our expertise is more reliable in performing tasks in an
                extraordinary manner. */}
              </div>
            </Col>
          </Row>
        </div>
        <hr style={{ height: 2, color: "white" }} />
        <div className="about2">
          <Row>
            <Col xs={12} md={5}>
              <div
                className="abt"
                style={{ color: "white", fontSize: 48, fontWeight: "medium" }}
              > {t('about.info_technology')}
                {/* Info Technology */}
              </div>
              <div
                className="prag"
                style={{ color: "white", fontSize: 18, marginTop: 20 }}
              >
                 {t('about.info_technology_description')}
                {/* it develops practical skills through the creation of products or
                solutions. */}
              </div>
            </Col>
          </Row>
        </div>
        <hr style={{ height: 2, color: "white" }} />
        <div className="about2">
          <Row>
            <Col xs={12} md={5}>
              <div
                className="abt"
                style={{ color: "white", fontSize: 48, fontWeight: "medium" }}
              >
                 {t('about.support')}
                {/* 24/7 Support */}
              </div>
              <div
                className="prag"
                style={{ color: "white", fontSize: 18, marginTop: 20 }}
              >
              {t('about.support_description')}
               
              </div>
            </Col>
          </Row>
        </div>
        <hr style={{ height: 2, color: "white" }} />
        <div className="about2">
          <Row>
            <Col xs={12} md={5}>
              <div
                className="abt"
                style={{ color: "white", fontSize: 48, fontWeight: "medium" }}
              >{t('about.it_management')}
                {/* IT Management */}
              </div>
              <div
                className="prag"
                style={{ color: "white", fontSize: 18, marginTop: 20 }}
              >
                {t('about.it_management_description')}
                {/* it ensures that all technology resoources and associated employees are utilized properly and in a manner that provides value. */}
              </div>
            </Col>
          </Row>
        </div>
        <hr style={{ height: 2, color: "white" }} />
        <div className="about2">
          <Row>
            <Col xs={12} md={5}>
              <div
                className="abt"
                style={{ color: "white", fontSize: 48, fontWeight: "medium" }}
              >
                {t('about.certified_company')}
                {/* Certified Company */}
              </div>
              <div
                className="prag"
                style={{ color: "white", fontSize: 18, marginTop: 20 }}
              >
                {t('about.certified_company_description')}
                {/* It outlines how to implement an information security managment system and identifies the mandatory */}
              </div>
            </Col>
          </Row>
        </div>
        <hr style={{ height: 2, color: "white" }} />
        <div className="about2">
          <Row>
            <Col xs={12} md={5}>
              <div
                className="abt"
                style={{ color: "white", fontSize: 48, fontWeight: "medium" }}
              >
                {t('about.data_analytics')}
                {/* Data Analytics */}
              </div>
              <div
                className="prag"
                style={{ color: "white", fontSize: 18, marginTop: 20 }}
              >
                {t ('about.data_analytics_description')}
                {/* it's the science of scrutinizing raw data in order to make
                closure about that information */}
              </div>
            </Col>
          </Row>
        </div>
        <hr style={{ height: 2, color: "white", marginBottom: 0 }} />

        {/* <div className="team">
          <Model3 />
        </div> */}
      </div>
    </div>
  );
}

export default About;
