import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "../src/Screens/Home/Home";
import About from "./Components/About/About";
import Services from "./Components/Services/Services";
import Contact from "./Components/Contact/Contact";
import OurPartners from './Components/OurPartners/OurPartners'

function rout() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/ourPartners" element={<OurPartners />} />
      </Routes>
    </div>
  );
}

export default rout;
