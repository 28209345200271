import React from "react"
import { Row, Container, Col } from "react-bootstrap"
import menu from '../../Assets/Images/menu icons.svg';
import Gradient1 from '../../Assets/Images/Gradient red.svg';
import logo1 from '../../Assets/Images/Nlogo_White_footage.svg';
import './Contact.css'
import { useEffect } from "react";
import { Contact } from "../../Screens/Home/contactus";
import SocialButtons from "./icons";
import TrackVisibility from 'react-on-screen';
import contactimg from '../../Assets/img/contact-img.svg'
import { TypeAnimation } from 'react-type-animation';
import { useTranslation } from 'react-i18next';


function Contacts() {
  const { t } = useTranslation();

    useEffect(() => {
        const ifameData = document.getElementById("iframeId")
        ifameData.src = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2145.068658750949!2d51.52828886399293!3d25.285346093442097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c534ffdce87f%3A0x44d9319f78cfd4b1!2sDoha%2C%20Qatar!5e0!3m2!1sen!2sin!4v1690202467028!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade`
        // ifameData.src=`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8580.338902324896!2d51.523746728494665!3d25.284437597770207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c534ffdce87f%3A0x44d9319f78cfd4b1!2sDoha%2C%20Qatar!5e0!3m2!1sen!2sin!4v1690202189227!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade`

    })
    return (
        <div>
          <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            
             
              <div className="text">
                <h3>

                <TypeAnimation
                      sequence={[
                        
                        'Delivering Digital Workplace Solutions.. ',
                        1000,
                      ]}
                      wrapper="span"
                      speed={50}
                      style={{ fontSize: '2em', display: 'inline-block' }}
                      repeat={Infinity}
                    />
                      </h3>       
               
                  <p>
                    {t('contact.our_accentuation')}
                    {/* Our accentuation is on offering high grade of product user friendliness through positive, creative and focused company staff. */}
                    </p>
                  
              </div>
            
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div style={{marginTop:-25,marginLeft:'-52%'}}className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img className="smallimg" src={contactimg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>

            {/* <div className="d-flex c-section">        
          
                <div className="contactform">
                    <Contact />
                </div>
                <div className="contacticons" >
                    <SocialButtons />
                </div>
            </div> */}

            <div className="map">
                <iframe id="iframeId" height="450px" width="100%"></iframe>
            </div>

        </div>

    )

} export default Contacts

